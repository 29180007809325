<template>
  <Modal
    :show="show"
    outer-modal-classes="min-h-full px-4 pb-4 pt-2 sm:p-0"
    @close="closeOnClickOutside"
  >
    <div
      :disabled="!isEditable"
    >
      <div class="flex md:flex-row flex-col md:space-y-0 space-y-5 p-6">
        <div class="w-full md:w-1/3 shrink-0 mr-4">
          <CardDescription
            :header="exerciseData.name"
            :helptext="$t('components.competence_model.matrix_wizard.exercise_modal.helptext')"
          />
        </div>
        <div class="grow min-w-0">
          <div class="space-y-5">
            <BaseInput
              v-model="exercise.name"
              :disabled="!isEditable"
              :label="$t('activerecord.attributes.exercise.name')"
              :error="getError('name')"
            />
            <BaseInput
              v-model="exercise.duration"
              :label="$t('activerecord.attributes.exercise.duration')
                + ' (in ' + $t('components.competence_model.matrix_wizard.exercise_modal.minutes') + ')'"
              min="5"
              step="5"
              type="number"
              :disabled="!isEditable"
              :error="getError('duration')"
            />
            <!-- eslint-disable vue/no-v-html -->
            <template v-if="showDurationWarning && exercise.isScheduled">
              <p
                v-if="durationIncreases && showDurationWarning"
                class="text-yellow-500 text-sm"
                v-html="$t('components.competence_model.matrix_wizard.exercise_modal.duration_increase_warning')"
              />
              <p
                v-else
                class="text-gray-500 text-sm"
                v-html="$t('components.competence_model.matrix_wizard.exercise_modal.duration_increase_info')"
              />
            </template>
            <BaseTextarea
              v-model="exercise.description"
              :label="$t('activerecord.attributes.exercise.description')"
              :disabled="!isEditable"
              :error="getError('description')"
            />
          </div>
        </div>
      </div>

      <MultiFileUpload
        :collection-path="exerciseFilesCollectionPath"
        accepted-mime-types="application/pdf, image/png, image/jpeg, video/quicktime, video/mp4,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.openxmlformats-officedocument.presentationml.presentation"
        accepted-file-types="PDF, PNG, JPEG, MOV, MP4, DOCX, PPTX"
        :disabled="!isEditable"
        :has-shareable-files="true"
        :errors="getError('files')"
        :parent-id="exercise.id"
        parent-type="Exercise"
        @preview-is-open="previewIsOpen = $event"
      />
    </div>

    <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4">
      <BaseButton
        type="button"
        :is-primary="false"
        :is-disabled="disabled"
        @click="$emit('close', exercise)"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        :is-disabled="!isEditable || disabled"
        :is-loading="disabled"
        name="submit_and_new"
        type="submit"
        @click="updateExercise('exercise-saved-and-new')"
      >
        {{ $t('defaults.save_and_new') }}
      </BaseButton>
      <BaseButton
        type="button"
        :is-disabled="!isEditable || disabled"
        :is-loading="disabled"
        qa-class="exercise-save-button"
        @click="updateExercise('exercise-saved')"
      >
        {{ $t('defaults.save') }}
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import Modal from '@/components/generic/Modal/Modal.vue';
import CardDescription from '@/components/generic/TwoColumnCard/CardDescription/CardDescription.vue';
import { exerciseFilesPath, exercisePath } from '@/util/url-helpers';
import { computed, ref } from 'vue';
import Axios from 'axios';

export default {
  name: 'MatrixWizardExerciseModal',
  components: {
    Modal,
    BaseInput,
    BaseButton,
    CardDescription,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    competenceModelId: {
      type: [Number, String],
      required: true,
    },
    exerciseData: {
      type: Object,
      required: true,
    },
    showDurationWarning: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'exercise-saved', 'exercise-saved-and-new'],
  setup(props, { emit }) {
    const exercise = ref({ ...props.exerciseData });
    const errors = ref({});
    const disabled = ref(false);
    const previewIsOpen = ref(false);
    const durationIncreases = computed(() => exercise.value.duration > props.exerciseData.duration);
    const getError = (...keys) => errors.value[keys.join('.')]?.[0];
    const exerciseFilesCollectionPath = exerciseFilesPath(props.competenceModelId, exercise.value);

    const updateExercise = (eventType) => {
      disabled.value = true;

      const data = new FormData();
      data.append('exercise[name]', exercise.value.name);
      data.append('exercise[duration]', exercise.value.duration);
      if (exercise.value.description) { data.append('exercise[description]', exercise.value.description); }

      Axios.put(exercisePath(props.competenceModelId, exercise.value), data)
        .then((response) => {
          errors.value = {};
          disabled.value = false;
          emit(eventType, response.data);
        })
        .catch((error) => {
          errors.value = error.response.data;
          disabled.value = false;
        });
    };

    function closeOnClickOutside() {
      if (!previewIsOpen.value) {
        emit('close', exercise);
      }
    }

    return {
      errors,
      previewIsOpen,
      disabled,
      getError,
      updateExercise,
      closeOnClickOutside,
      durationIncreases,
      exerciseFilesCollectionPath,
      exercise,
    };
  },
};
</script>
