<template>
  <PageHeading>
    <div class="flex flex-col">
      <div
        v-if="!competenceModel?.customized && (competenceModel?.name && competenceModel?.organisation)"
        class="flex flex-row space-x-2"
      >
        <p class="text-2xl font-bold truncate">
          {{ selectedCompetenceModel ? selectedCompetenceModel.name : competenceModel.name }}
        </p>
        <p class="text-2xl font-normal max-w-min truncate">
          {{ organisationName }}
        </p>
      </div>
      <div
        v-else
        class="truncate"
      >
        {{ title }}
      </div>
      <div
        v-if="competenceModel && competenceModel.parentTemplate &&
          competenceModel?.customized && !selectedCompetenceModel"
        class="flex justify-start space-x-1.5 cursor-pointer mt-2"
        @click="$emit('open-parent-template')"
      >
        <div class="justify-center">
          <ExternalLinkIcon
            class="justify-center h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <p class="text-sm font-medium text-gray-500">
          {{ $t("components.assessment.competence_model_form.categories.edit_competence_model.template_link",
                {name: competenceModel.parentTemplate.name,
                 organisation: competenceModel.parentTemplate.organisation })
          }}
        </p>
      </div>
    </div>
    <template
      v-if="isEditable"
      #actions
    >
      <BaseButton
        v-if="!competenceModel && !selectedCompetenceModel"
        :is-primary="false"
        icon="PlusIcon"
        qa-class="add-competence-model"
        @click="$emit('show-creation-modal')"
      >
        {{ $t("components.assessment.competence_model_form.creation_modal.new_competence_model") }}
      </BaseButton>
      <div
        v-else
        class="flex space-x-3"
      >
        <Dropdown
          v-if="isCustomized && !selectedCompetenceModel && !showReplaceCompetenceModel"
          class="inline-flex relative"
        >
          <template #button>
            <BaseButton
              as="MenuButton"
              class="plain focus:z-10"
              icon="ChevronDownIcon"
              :is-primary="false"
              :leading-icon="false"
              :disabled="formDisabled"
              :is-loading="formDisabled"
            >
              <slot>
                {{ $t('defaults.options') }}
              </slot>
            </BaseButton>
          </template>
          <ActionDropdownItem
            @click="$emit('show-save-as-template-modal')"
          >
            {{ $t("components.assessment.competence_model_form.save_as_template") }}
          </ActionDropdownItem>
          <ActionDropdownItem
            v-if="competenceModel.parentTemplate"
            @click="$emit('show-template-reset-modal')"
          >
            {{ $t("components.assessment.competence_model_form.reset_template") }}
          </ActionDropdownItem>
          <ActionDropdownItem
            @click="$emit('replace-competence-model')"
          >
            {{ $t("defaults.replace") }}
          </ActionDropdownItem>
          <ActionDropdownItem
            @click="$emit('show-customized-template-remove-modal')"
          >
            {{ $t("components.assessment.competence_model_form.remove_competence_model") }}
          </ActionDropdownItem>
        </Dropdown>
        <template v-else-if="selectedCompetenceModel || showReplaceCompetenceModel">
          <BaseButton
            :is-primary="false"
            @click="$emit('on-competence-model-remove')"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
          <BaseButton
            :is-disabled="modelIsSelected"
            :is-loading="formDisabled"
            @click="$emit('save-selected-template')"
          >
            {{ $t('defaults.save') }}
          </BaseButton>
        </template>
        <template v-else-if="!isCustomized">
          <BaseButton
            :is-primary="false"
            icon-style="text-gray-500"
            icon="XIcon"
            @click="$emit('show-customized-template-remove-modal')"
          >
            {{ $t('defaults.remove') }}
          </BaseButton>
          <BaseButton
            :is-primary="false"
            icon-style="text-gray-500"
            icon="RefreshIcon"
            @click="$emit('replace-competence-model')"
          >
            {{ $t('defaults.replace') }}
          </BaseButton>
          <BaseButton
            icon="PencilIcon"
            @click="$emit('customize-competence-model')"
          >
            {{ $t('defaults.customize') }}
          </BaseButton>
        </template>
      </div>
    </template>
  </PageHeading>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { ExternalLinkIcon } from '@heroicons/vue/outline';
import PageHeading from '@/components/generic/PageHeading/PageHeading.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import Dropdown from '@/components/generic/Dropdown/Dropdown.vue';
import ActionDropdownItem from '@/components/generic/Dropdown/ActionDropdownItem/ActionDropdownItem.vue';

export default defineComponent({
  name: 'AssessmentCompetenceModelFormHeader',
  components: {
    PageHeading,
    BaseButton,
    Dropdown,
    ActionDropdownItem,
    ExternalLinkIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    competenceModel: {
      type: Object,
      default: null,
    },
    selectedCompetenceModel: {
      type: Object,
      default: null,
    },
    modelIsSelected: {
      type: Boolean,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    isCustomized: {
      type: Boolean,
      required: false,
    },
    formDisabled: {
      type: Boolean,
      required: true,
    },
    showReplaceCompetenceModel: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['open-parent-template', 'show-creation-modal', 'show-save-as-template-modal',
    'show-template-reset-modal', 'show-customized-template-remove-modal', 'replace-competence-model',
    'customize-competence-model', 'on-competence-model-remove', 'save-selected-template'],
  setup(props) {
    const organisationName = computed(() => (props.selectedCompetenceModel
      ? props.selectedCompetenceModel?.organisation?.displayName
      : props.competenceModel?.organisation?.displayName));
    return {
      organisationName,
    };
  },
});
</script>
