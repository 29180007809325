<template>
  <div class="flex flex-col gap-y-6">
    <page-heading>
      {{ $t('activerecord.models.assessment.other') }}
      <template #actions>
        <div class="flex items-center space-x-4">
          <search-input />
          <BaseButton
            :is-primary="false"
            :leading-icon="false"
            icon-style="rotate-90"
            icon="AdjustmentsIcon"
            @click="openFilterModal = true"
          >
            {{ $t('components.assessments.assessment_filters.title') }}
          </BaseButton>
          <BaseButton
            as="a"
            :href="newAssessmentPath"
            icon="PlusIcon"
            qa-class="assessments-new-button"
          >
            {{ $t('components.assessments.new_assessment') }}
          </BaseButton>
        </div>
      </template>
    </page-heading>

    <AssessmentFilters
      :open-filter-modal="openFilterModal"
      :available-assessment-filters="availableAssessmentFilters"
      @close="openFilterModal = false"
    />

    <div v-if="assessmentsPresent">
      <BaseTable
        :columns="columns"
        :records="assessments"
        :pages="pages"
        :emit-row-click="true"
        @on-row-click="visitAssessmentPath"
      >
        <template #default="{ record: assessment }">
          <td
            v-dompurify-html:mark="highlightedText(assessment.name)"
            class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ph-no-capture"
          />
          <td
            v-if="!isOrganisationAssessments"
            v-dompurify-html:mark="highlightedText(assessment.organisation.displayName)"
            class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ph-no-capture"
          />
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ assessment.date }}
          </td>
          <td
            v-dompurify-html:mark="highlightedText(assessment.targetPosition)"
            class="px-6 py-4 whitespace-nowrap text-sm text-gray-600 ph-no-capture"
          />
          <td class="px-6 py-4 whitespace-nowrap">
            <AssessmentStatusBadge :status="assessment.status" />
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ assessment.expiresAt }}
          </td>

          <AssessmentActions
            :assessment="assessment"
            :organisation-id="organisationId"
          />
        </template>
      </BaseTable>
    </div>
    <div
      v-else
      class="mt-32"
    >
      <div
        class="text-center object-center content-center justify-center space-y-6"
      >
        <div>
          <svg
            class="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <p
            class="text-gray-900 text-sm leading-5 font-medium"
          >
            {{ $t('components.assessments.assessments_table.assessments_empty_state.header') }}
          </p>
          <p
            class="text-gray-500 text-sm leading-5 font-normal"
          >
            {{ $t('components.assessments.assessments_table.assessments_empty_state.header_helptext') }}
          </p>
        </div>
        <div>
          <base-button
            as="a"
            :href="newAssessmentPath"
            icon="PlusIcon"
          >
            {{ $t('components.assessments.assessments_table.assessments_empty_state.create_button') }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from '@/components/generic/BaseTable/BaseTable.vue';
import { useI18n } from '@/plugins/i18n';
import { createAssessmentPath, createOrganisationAssessmentPath, editOrganisationAssessmentPath, editAssessmentPath } from '@/util/url-helpers';
import { ref } from 'vue';
import { useSearchHighlighter } from '@/composables/useSearchHighlighter';
import AssessmentFilters from '../AssessmentFilters/AssessmentFilters.vue';
import AssessmentStatusBadge from '../AssessmentStatusBadge/AssessmentStatusBadge.vue';
import AssessmentActions from './AssessmentActions/AssessmentActions.vue';

export default {
  components: {
    BaseTable,
    AssessmentStatusBadge,
    AssessmentActions,
    AssessmentFilters,
  },
  props: {
    assessments: {
      type: Array,
      required: true,
    },
    organisationId: {
      type: Number,
      required: false,
      default: null,
    },
    availableAssessmentFilters: {
      type: Object,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
    assessmentsPresent: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const isOrganisationAssessments = props.organisationId;

    const openFilterModal = ref(false);
    const { highlightedText } = useSearchHighlighter();

    const columns = [
      {
        key: 'name',
        name: t('activerecord.attributes.assessment.name'),
        sortable: true,
      },
      ...(!isOrganisationAssessments ? [
        {
          key: 'organisation',
          name: t('activerecord.attributes.assessment.organisation'),
          sortable: false,
        },
      ] : []),
      {
        key: 'start_date',
        name: t('activerecord.attributes.assessment.date'),
        sortable: true,
        association: 'schedule',
      },
      {
        key: 'target_position',
        name: t('activerecord.attributes.assessment.target_position'),
        sortable: true,
      },
      {
        key: 'status',
        name: t('activerecord.attributes.assessment.status'),
        sortable: true,
      },
      {
        key: 'expires_at',
        name: t('activerecord.attributes.assessment.expires_at'),
        sortable: true,
      },
      {
        key: 'actions',
        name: t('defaults.manage'),
        hidden: true,
      },
    ];

    const newAssessmentPath = isOrganisationAssessments
      ? createOrganisationAssessmentPath(props.organisationId) : createAssessmentPath();

    const visitAssessmentPath = (assessment) => {
      const editPath = props.organisationId
        ? editOrganisationAssessmentPath(props.organisationId, assessment) : editAssessmentPath(assessment);
      Turbo.visit(editPath);
    };

    return {
      columns,
      newAssessmentPath,
      openFilterModal,
      highlightedText,
      isOrganisationAssessments,
      visitAssessmentPath,
    };
  },
};
</script>
