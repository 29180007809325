<template>
  <Modal
    :show="true"
    inner-modal-classes="w-full sm:min-w-3/4 md:min-w-0 md:max-w-4xl"
    :hide-overflow="false"
    @click-outside="close"
  >
    <LoadingSlider
      v-if="templateDocument?.isPending"
      :progress="uploadProgress"
      :height="10"
      :is-rounded="true"
    />
    <div class="flex flex-col p-6 w-full">
      <h2 class="text-gray-900 text-lg font-medium leading-normal mb-5">
        {{ modalHeader }}
      </h2>
      <form
        :disabled="!isEditable || isLoading"
        @keydown.enter="saveReportTemplate"
      >
        <TabGroup>
          <TabList
            class="flex"
          >
            <Tab
              v-slot="{ selected }"
              as="template"
            >
              <button
                :class="['p-2  cursor-pointer text-sm font-medium flex-1',
                         selected ? 'border-primary-500 text-primary-600 border-b-2' :
                         'text-gray-500 border-gray-300 border-b-2']"
              >
                {{ $t('components.report_templates.modal.tabs.basic') }}
              </button>
            </Tab>
            <Tab
              v-slot="{ selected }"
              as="template"
            >
              <button
                :class="['p-2  cursor-pointer text-sm font-medium flex-1',
                         selected ? 'border-primary-500 text-primary-600 border-b-2' :
                         'text-gray-500 border-gray-300 border-b-2']"
              >
                {{ $t('components.report_templates.modal.tabs.charts') }}
              </button>
            </Tab>
            <Tab
              v-slot="{ selected }"
              as="template"
            >
              <button
                :class="['p-2  cursor-pointer text-sm font-medium flex-1',
                         selected ? 'border-primary-500 text-primary-600 border-b-2' :
                         'text-gray-500 border-gray-300 border-b-2']"
              >
                {{ $t('components.report_templates.modal.tabs.ai') }}
              </button>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel class="space-y-5  pt-5">
              <BaseInput
                v-model="name"
                :disabled="!isEditable"
                :label="$t('activerecord.attributes.report_template.name')"
                :required="true"
                :trailing-unit="templateDocumentType"
                :error="getError('name')"
              />

              <div>
                <NamePlaceholderField
                  v-model:name-placeholder="placeholder"
                  :disabled="!isEditable"
                  :default-name-placeholder="fileBasename"
                  :document-type="templateDocumentType"
                  :initial-preview-filename="fileNamePreview"
                  :error="getError('name_placeholder')"
                />
              </div>

              <SelectList
                v-model:selected="locale"
                :label="$t('activerecord.attributes.report_template.locale')"
                name="report_template[locale]"
                :options="localeOptions"
                :error="getError('locale')"
                :disabled="!isEditable"
              />

              <SearchableSelectList
                v-if="!assessmentId"
                id="organisation-model-select"
                :selected="currentSelectedOrganisation"
                :options="availableOrganisations"
                :options-empty-message="$t('components.organisations.no_organisations')"
                value-prop="id"
                label-prop="displayName"
                name="report_template[organisation_id]"
                :disabled="!isEditable || !!initialOrganisationId"
                :allow-reset="false"
                :error="getError('organisation_id')"
                :label="$t('activerecord.attributes.assessment.organisation')"
                @update:selected="currentSelectedOrganisation = $event"
              />
              <div
                class="flex justify-between items-center"
              >
                <div>
                  <p class="text-base font-medium text-gray-900 pr-4">
                    {{ $t('components.report_templates.modal.option_percentage.title') }}
                  </p>
                  <span class="text-sm text-gray-500">
                    {{ $t('components.report_templates.modal.option_percentage.text') }}
                    <a
                      :href="$t('components.report_templates.modal.option_percentage.info_link')"
                      class="text-primary-500 text-sm leading-5 inline-block"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ $t('defaults.more_information') }}
                    </a>
                  </span>
                </div>
                <Toggle
                  v-model="usePercentageRating"
                  :disabled="!isEditable"
                  @update:model-value="usePercentageRating=$event"
                />
              </div>
            </TabPanel>
            <TabPanel class="space-y-4  pt-4">
              <p class="text-gray-500 text-sm mt-1">
                {{ $t('components.report_templates.modal.charts_settings_help_text') }}
              </p>
              <div class="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                <div class="w-full md:w-1/2 flex flex-col space-y-4">
                  <BaseInput
                    v-model="competencyRatingSeriesLegend"
                    :disabled="!isEditable"
                    :label="$t('activerecord.attributes.report_template.competency_rating_series_legend')"
                    :placeholder="$t('generate_report.charts.rating', null, {locale: locale.value} )"
                    :error="getError('competency_rating_series_legend')"
                  />
                  <BaseInput
                    v-model="clusterRatingSeriesLegend"
                    :disabled="!isEditable"
                    :label="$t('activerecord.attributes.report_template.cluster_rating_series_legend')"
                    :placeholder="$t('generate_report.charts.rating', null, {locale: locale.value})"
                    :error="getError('cluster_rating_series_legend')"
                  />
                  <BaseInput
                    v-model="exerciseRatingSeriesLegend"
                    :disabled="!isEditable"
                    :label="$t('activerecord.attributes.report_template.exercise_rating_series_legend')"
                    :placeholder="$t('generate_report.charts.rating', null, {locale: locale.value})"
                    :error="getError('exercise_rating_series_legend')"
                  />
                </div>
                <div class="w-full md:w-1/2 flex flex-col space-y-4">
                  <BaseInput
                    v-model="targetProfileMinSeriesLegend"
                    :disabled="!isEditable"
                    :label="$t('activerecord.attributes.report_template.target_profile_min_series_legend')"
                    :placeholder="$t('generate_report.charts.target_profile_min', null, {locale: locale.value})"
                    :error="getError('target_profile_min_series_legend')"
                  />
                  <BaseInput
                    v-model="targetProfileMaxSeriesLegend"
                    :disabled="!isEditable"
                    :label="$t('activerecord.attributes.report_template.target_profile_max_series_legend')"
                    :placeholder="$t('generate_report.charts.target_profile_max', null, {locale: locale.value})"
                    :error="getError('target_profile_max_series_legend')"
                  />
                  <BaseInput
                    v-model="targetProfileRangeSeriesLegend"
                    :disabled="!isEditable"
                    :label="$t('activerecord.attributes.report_template.target_profile_range_series_legend')"
                    :placeholder="$t('generate_report.charts.target_profile_min', null, {locale: locale.value})"
                    :error="getError('target_profile_range_series_legend')"
                  />
                </div>
              </div>
              <BaseInput
                v-model="selfAssessmentSeriesLegend"
                :disabled="!isEditable"
                :label="$t('activerecord.attributes.report_template.self_assessment_series_legend')"
                :placeholder="$t('generate_report.charts.self_assessment', null, {locale: locale.value})"
                :error="getError('self_assessment_series_legend')"
              />
            </TabPanel>
            <TabPanel class=" pt-5">
              <Alert class="mb-5">
                <template #title>
                  {{ $t('components.report_templates.modal.ai_disclaimer_title') }}
                </template>
                <template #content>
                  {{ $t('components.report_templates.modal.ai_disclaimer_text') }}
                </template>
              </Alert>
              <BaseTextarea
                v-model="aiSummaryPlaceholderExample"
                classes="h-56"
                :label="$t('activerecord.attributes.report_template.ai_summary_placeholder_example')"
                :disabled="!isEditable"
                :error="getError('ai_summary_placeholder_example')"
                size="large"
                @keydown.enter.stop
              />
              <p class="text-gray-500 text-sm mt-1">
                {{ $t('components.report_templates.modal.example_summary_helptext') }}
              </p>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </form>
    </div>

    <footer class="flex p-2 justify-end bg-gray-50 py-3 px-4 flex-row space-x-4 rounded-b-lg">
      <BaseButton
        type="button"
        :is-primary="false"
        @click="close"
      >
        {{ $t('defaults.cancel') }}
      </BaseButton>
      <BaseButton
        type="button"
        :is-disabled="!isEditable || templateDocument?.isPending"
        :is-loading="isLoading"
        @click="saveReportTemplate"
      >
        <span>{{ $t('defaults.save') }}</span>
      </BaseButton>
    </footer>
  </Modal>
</template>

<script>
import Modal from '@/components/generic/Modal/Modal.vue';
import { assessmentReportTemplatePath, assessmentReportTemplatesPath, reportTemplatePath, reportTemplatesPath } from '@/util/url-helpers';
import Axios from 'axios';
import { computed, defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useLocaleOptions from '@/composables/localeOptions';
import SelectList from '@/components/generic/SelectList/SelectList.vue';
import {
  TabGroup, TabList, Tab, TabPanels, TabPanel,
} from '@headlessui/vue';
import BaseTextarea from '@/components/generic/BaseTextarea/BaseTextarea.vue';
import LoadingSlider from '@/components/Candidates/EditCandidate/LoadingSlider.vue';
import NamePlaceholderField from './ReportTemplateNamePlaceholderField.vue';
import { useReportTemplatesStore } from './stores/reportTemplatesStore';

export default defineComponent({
  name: 'ReportTemplateModal',
  components: {
    Modal,
    NamePlaceholderField,
    BaseTextarea,
    SelectList,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    LoadingSlider,
  },
  props: {
    reportTemplate: {
      type: Object,
      default: () => null,
      required: false,
    },
    templateDocument: {
      type: Object,
      default: () => null,
    },
    assessmentId: {
      type: Number,
      default: null,
      required: false,
    },
    organisationId: {
      type: Number,
      default: null,
    },
    availableOrganisations: {
      type: Array,
      required: false,
      default: () => [],
    },
    uploadProgress: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const toast = inject('toast');
    const { t, availableLocales, locale: currentLocal } = useI18n();
    const store = useReportTemplatesStore();

    const errors = ref({});
    const isEditable = ref(true);

    const name = ref(
      props.reportTemplate?.name
        || props.templateDocument?.filename?.replace(/\.[^.]+$/, ''),
    );

    const initialOrganisationId = props.organisationId;
    const currentSelectedOrganisation = props.assessmentId ? ref(null) : ref(props.reportTemplate?.organisation
    || props.availableOrganisations.find((org) => org.id === initialOrganisationId)
    || null);

    const placeholder = ref(props.reportTemplate?.namePlaceholder || '');
    const aiSummaryPlaceholderExample = ref(props.reportTemplate?.aiSummaryPlaceholderExample || '');
    const competencyRatingSeriesLegend = ref(props.reportTemplate?.competencyRatingSeriesLegend || '');
    const clusterRatingSeriesLegend = ref(props.reportTemplate?.clusterRatingSeriesLegend || '');
    const exerciseRatingSeriesLegend = ref(props.reportTemplate?.exerciseRatingSeriesLegend || '');
    const targetProfileMinSeriesLegend = ref(props.reportTemplate?.targetProfileMinSeriesLegend || '');
    const targetProfileMaxSeriesLegend = ref(props.reportTemplate?.targetProfileMaxSeriesLegend || '');
    const targetProfileRangeSeriesLegend = ref(props.reportTemplate?.targetProfileRangeSeriesLegend || '');
    const selfAssessmentSeriesLegend = ref(props.reportTemplate?.selfAssessmentSeriesLegend || '');

    const { localeOptions, initialLocale } = useLocaleOptions(
      availableLocales,
      props.reportTemplate?.locale || currentLocal.value,
    );
    const locale = ref(initialLocale);
    const usePercentageRating = ref(props.reportTemplate?.usePercentageRating || false);

    const isLoading = ref(false);

    const modalHeader = computed(() => {
      if (props.reportTemplate && props.templateDocument) {
        return t('components.report_templates.modal.replace_document.title');
      }
      return props.templateDocument
        ? t('components.report_templates.modal.create.title')
        : t('components.report_templates.modal.edit.title');
    });

    const getError = (...keys) => errors.value[keys.join('.')]?.[0];

    const close = () => {
      emit('close');
    };

    const saveReportTemplate = () => {
      isEditable.value = false;
      isLoading.value = true;

      const data = {
        report_template: {
          name: name.value,
          name_placeholder: placeholder.value,
          organisation_id: currentSelectedOrganisation?.value?.id,
          template_document: props.templateDocument?.signed_id || '',
          locale: locale.value?.value,
          use_percentage_rating: usePercentageRating.value,
          ai_summary_placeholder_example: aiSummaryPlaceholderExample.value,
          competency_rating_series_legend: competencyRatingSeriesLegend.value,
          cluster_rating_series_legend: clusterRatingSeriesLegend.value,
          exercise_rating_series_legend: exerciseRatingSeriesLegend.value,
          target_profile_min_series_legend: targetProfileMinSeriesLegend.value,
          target_profile_max_series_legend: targetProfileMaxSeriesLegend.value,
          target_profile_range_series_legend: targetProfileRangeSeriesLegend.value,
          self_assessment_series_legend: selfAssessmentSeriesLegend.value,
        },
      };

      let url;
      if (props.assessmentId) {
        data.report_template.assessment_id = props.assessmentId;
        url = props.reportTemplate ? assessmentReportTemplatePath(props.assessmentId, props.reportTemplate)
          : assessmentReportTemplatesPath(props.assessmentId);
      } else {
        url = props.reportTemplate
          ? reportTemplatePath(props.reportTemplate)
          : reportTemplatesPath();
      }

      Axios({
        url,
        data,
        method: props.reportTemplate ? 'PUT' : 'POST',
      })
        .then((response) => {
          let message = t('components.report_templates.modal.create.success');
          if (props.reportTemplate) {
            if (props.templateDocument) {
              message = t('components.report_templates.modal.replace_document.success');
            } else {
              message = t('components.report_templates.modal.edit.success');
            }
          }
          toast.success(message);
          store.upsertReportTemplate(response.data);
          errors.value = {};
          close();
        })
        .catch(({ response }) => {
          isEditable.value = true;
          isLoading.value = false;
          if (response.status === 423) {
            toast.error(t(response.data.errors));
          } else {
            errors.value = response.data.errors;
          }
        });
    };

    const templateDocumentType = computed(() => {
      const type = props.templateDocument?.content_type || props.reportTemplate?.contentType;
      if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return '.docx';
      if (type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return '.pptx';
      return null;
    });

    const fileBasename = computed(() => props.templateDocument?.filename?.replace(/\.[^/.]+$/, '')
    || props.reportTemplate?.templateDocument?.filename.replace(/\.[^/.]+$/, ''));

    const fileNamePreview = ref(props.reportTemplate?.fileNamePreview
      ? t('components.report_templates.modal.file_name_preview', { name: props.reportTemplate.fileNamePreview })
      : '');

    return {
      name,
      aiSummaryPlaceholderExample,
      placeholder,
      localeOptions,
      locale,
      competencyRatingSeriesLegend,
      clusterRatingSeriesLegend,
      exerciseRatingSeriesLegend,
      targetProfileMinSeriesLegend,
      targetProfileMaxSeriesLegend,
      targetProfileRangeSeriesLegend,
      selfAssessmentSeriesLegend,
      isLoading,
      isEditable,
      templateDocumentType,
      modalHeader,
      fileBasename,
      fileNamePreview,
      currentSelectedOrganisation,
      initialOrganisationId,
      usePercentageRating,

      getError,
      close,
      saveReportTemplate,
    };
  },
});
</script>
