<template>
  <div>
    <div
      ref="componentRef"
      class="relative mt-1 rounded-md shadow-sm w-80"
    >
      <input
        id="name"
        ref="input"
        v-model="inputValue"
        type="text"
        name="name"
        autocomplete="off"
        class="block w-full rounded-md border-gray-300 pl-3 pr-32
         focus:border-primary-500 focus:ring-primary-500 sm:text-sm
         qa-add-competency"
        :placeholder="$t('components.competence_model.matrix_wizard.form_controls.input_trailing_select.placeholder')"
        @keydown.enter.prevent="onCreateInput"
      >
      <div class="absolute inset-y-0 right-0 flex items-center">
        <label
          for="type"
          class="sr-only"
        >
          Select
        </label>

        <select
          id="type"
          v-model="selectedOption"
          name="type"
          class="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7
           text-gray-500 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
        >
          <option
            v-for="option in options"
            :key="option.id"
            :value="option.id"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="flex flex-row flex-nowrap m-2">
      <p
        v-if="getError('name')"
        class="text-red-500"
      >
        {{ $t('activerecord.attributes.competency.name') }} {{ getError('name') }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue';
import { competenciesPath, competencyClustersPath } from '@/util/url-helpers';
import { useI18n } from 'vue-i18n';
import { onClickOutside } from '@vueuse/core';
import Axios from 'axios';

export default defineComponent({
  name: 'MatrixWizardInputSelect',
  components: {},
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    competenceModelId: {
      type: Number,
      required: true,
    },
    competencyClusterId: {
      type: Number,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['createdCompetency', 'createdCompetencyClusters', 'error', 'showInputSelection'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const inputValue = ref(props.modelValue);

    const options = [{ id: 'competency', label: t('activerecord.models.competency') },
      { id: 'competency_cluster', label: t('activerecord.models.competency_cluster.short') }];

    const selectedOption = ref('competency');
    const componentRef = ref();
    const input = ref(null);

    const errors = ref({});
    const getError = (...keys) => errors.value[keys.join('.')]?.[0];

    const onCreateInput = () => {
      let url = null;
      const data = {};
      data[selectedOption.value] = {
        name: inputValue.value,
        competence_model_id: props.competenceModelId,
      };

      if (selectedOption.value === 'competency') {
        data[selectedOption.value].competency_cluster_id = props.competencyClusterId;
        url = competenciesPath(props.competenceModelId);
      } else {
        url = competencyClustersPath(props.competenceModelId);
      }

      Axios.post(url, data)
        .then((response) => {
          errors.value = {};
          inputValue.value = '';

          if (Array.isArray(response.data)) {
            emit('createdCompetencyClusters', response.data);
          } else {
            emit('createdCompetency', response.data);
          }
        })
        .catch((error) => {
          errors.value = error.response.data;
          inputValue.value = '';
          emit('error');
        });
    };

    onClickOutside(componentRef, () => {
      if (componentRef.value && inputValue.value !== '') {
        onCreateInput();
      }
    });

    return {
      onCreateInput,
      selectedOption,
      componentRef,
      getError,
      inputValue,
      input,
      options,
    };
  },
});
</script>
