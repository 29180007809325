<!-- eslint-disable vue/no-v-html -->
<template>
  <button
    :class="['bg-white rounded-md flex items-center p-2 text-sm',
             'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500  relative',
             newPosts ? 'text-primary-500' :'text-gray-500']"
    @click="openSlideOver"
  >
    <GiftIcon class="block h-5 w-5 mr-1" />
    Updates
    <div
      v-if="newPosts"
      class="w-2 h-2 bg-primary-500 absolute right-0 top-0 rounded-full self-start"
    />
  </button>
  <SlideOver
    :open="isOpen"
    class="h-screen"
    @click-outside="closeSlideOver"
    @close-clicked="closeSlideOver"
  >
    <template #title>
      Applysia Updates
    </template>
    <template #content>
      <LoadingSlider
        v-show="isLoading"
      />
      <div class="flex flex-col">
        <div
          v-for="post in releasePosts"
          :key="post.id"
          class="pb-8 border-b border-gray-300 mt-8 mx-4 space-y-5"
        >
          <a
            :href="post.link"
            target="_blank"
          >
            <img :src="post.imageLink">
            <h3
              class="font-medium text-gray-800 text-lg mt-4 mb-2 hover:text-gray-600"
              v-html="post.title.rendered"
            />

            <p
              class="text-gray-600 font-normal text-base"
              v-html="post.excerpt.rendered"
            />
          </a>
        </div>
      </div>
    </template>
  </SlideOver>
</template>
<script>
import SlideOver from '@/components/generic/SlideOver/SlideOver.vue';
import { GiftIcon } from '@heroicons/vue/outline';

import { ref, watch, onMounted } from 'vue';
import LoadingSlider from '@/components/Candidates/EditCandidate/LoadingSlider.vue';

export default {
  components: {
    SlideOver,
    LoadingSlider,
    GiftIcon,
  },
  setup() {
    const isLoading = ref(true);
    const releasePosts = ref([]);

    const isOpen = ref(false);
    const newPosts = ref(false);

    const loadPosts = async () => {
      let postData = await fetch('https://applysia.de/wp-json/wp/v2/posts?categories=22&per_page=10&context=embed');
      postData = await postData.json();

      const mediaIds = postData.map((p) => p.featured_media).join(',');
      let mediaData = await fetch(`https://applysia.de/wp-json/wp/v2/media?include=${mediaIds}&context=embed`);
      mediaData = await mediaData.json();

      releasePosts.value = postData.map((p) => ({
        ...p,
        imageLink: mediaData.find((m) => m.id === p.featured_media).link,
      }));
    };

    watch(isOpen, async () => {
      if (isOpen.value === true) {
        isLoading.value = true;
        await loadPosts();
        isLoading.value = false;
      }
    });

    const openSlideOver = () => {
      const currentTime = new Date();
      localStorage.setItem('lastOpenedUpdateSlideOverTimestamp', currentTime.getTime());

      isOpen.value = true;
      newPosts.value = false;
    };

    const closeSlideOver = () => {
      isOpen.value = false;
    };

    function checkedForUpdatesRecently() {
      // Check if a timestamp is stored in localStorage
      const lastRunTimestamp = localStorage.getItem('lastCheckedForReleasePostsTimestamp');

      if (lastRunTimestamp) {
        // Convert the stored timestamp to a Date object
        const lastRunDate = new Date(parseInt(lastRunTimestamp, 10));

        // Calculate the current time
        const currentTime = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = currentTime - lastRunDate;

        // Calculate the time difference in hours
        const hoursDifference = timeDifference / (1000 * 60 * 60);

        // Check if the last run was within the last 6 hours
        if (hoursDifference < 1) {
          return true;
        }
      }

      return false;
    }

    function markCheckedForUpdates() {
      // Get the current timestamp
      const currentTimestamp = new Date().getTime();

      // Store the current timestamp in localStorage
      localStorage.setItem('lastCheckedForReleasePostsTimestamp', currentTimestamp);
    }

    function lastOpenedBeforeDate(dateString) {
      // Parse the provided date string
      const providedDate = new Date(dateString);

      // Get the stored timestamp from localStorage
      const storedTimestamp = localStorage.getItem('lastOpenedUpdateSlideOverTimestamp');

      // Check if a timestamp is stored
      if (storedTimestamp) {
        // Parse the stored timestamp as a number
        const storedTimestampNumber = parseInt(storedTimestamp, 10);

        // Create a Date object from the stored timestamp
        const storedDate = new Date(storedTimestampNumber);

        // Compare the provided date with the stored date
        if (providedDate > storedDate) {
          return true;
        }
        return false;
      }

      return true;
    }

    onMounted(async () => {
      // Not checked for the last 2 hours
      if (!checkedForUpdatesRecently()) {
        let postData = await fetch('https://applysia.de/wp-json/wp/v2/posts?categories=22&per_page=10&context=embed');
        postData = await postData.json();

        localStorage.setItem('newestReleasePostDate', postData[0].date);

        markCheckedForUpdates();
      }

      // If newest post is newer that the last time the SlideOver was opened
      const newestReleasePostDate = localStorage.getItem('newestReleasePostDate');
      if (lastOpenedBeforeDate(newestReleasePostDate)) {
        newPosts.value = true;
      }
    });

    return {
      isOpen,
      openSlideOver,
      closeSlideOver,
      newPosts,
      isLoading,
      releasePosts,
    };
  },
};

</script>
