<template>
  <DialogModal
    :open="open"
    @close="close"
  >
    <template #header>
      {{ title || $t('defaults.confirm') }}
    </template>
    <template #main>
      <div>
        <p class="qa-confirm-dialog-message">
          {{ message }}

          <template v-if="infoLink">
            <a
              :href="infoLink"
              class="text-primary-500 text-sm leading-5 inline-block"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('defaults.more_information') }}
            </a>
          </template>
        </p>

        <ul
          v-for="item in listing"
          :key="item"
          class="pl-2 list-inside list-disc"
        >
          <li><span class="-ml-2">{{ item }}</span></li>
        </ul>

        <template v-if="challenge">
          <i18n-t
            keypath="components.confirm_dialog_modal.please_type_to_confirm"
            tag="p"
            class="my-2"
          >
            <span class="font-semibold text-gray-700">
              {{ challenge }}
            </span>
          </i18n-t>

          <BaseInput
            v-model="challengeInput"
            :required="true"
            class="mt-4 qa-confirm-dialog-input"
            @keyup.enter="confirm"
          />
        </template>
      </div>
    </template>
    <template #footer>
      <div class="bg-gray-50 w-full px-1 sm:flex sm:w-1 sm:flex-row-reverse">
        <BaseButton
          :is-disabled="!isValid || confirmed"
          :is-loading="confirmed || isLoading"
          class="mx-auto p-2 w-full justify-center rounded-md border border-transparent
           shadow-sm sm:ml-3 sm:w-auto sm:text-sm qa-confirm-dialog-submit"
          @click="confirm"
        >
          {{ buttonText || $t('defaults.confirm') }}
        </BaseButton>
        <BaseButton
          :is-disabled="confirmed"
          :is-primary="false"
          class="mt-3 w-full justify-center rounded-md border border-gray-300
           shadow-sm p-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          @click="close"
        >
          {{ $t('defaults.cancel') }}
        </BaseButton>
      </div>
    </template>
  </DialogModal>
</template>

<script>
import {
  defineComponent, ref, computed, toRefs, watch,
} from 'vue';
import DialogModal from '../DialogModal/DialogModal.vue';
import BaseInput from '../BaseInput/BaseInput.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default defineComponent({
  components: {
    DialogModal,
    BaseInput,
    BaseButton,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    infoLink: {
      type: String,
      required: false,
      default: null,
    },
    challenge: {
      type: String,
      required: false,
      default: '',
    },
    open: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    buttonText: {
      type: String,
      default: null,
      required: false,
    },
    listing: {
      type: Array,
      required: false,
      default: null,
    },
  },
  emits: ['confirm', 'close'],
  setup(props, { emit }) {
    const { open } = toRefs(props);

    const challengeInput = ref('');
    const confirmed = ref(false);

    const isValid = computed(() => {
      if (props.challenge) {
        return challengeInput.value === props.challenge;
      }
      return true;
    });

    const confirm = () => {
      if (isValid.value && !props.isLoading) {
        emit('confirm');
        confirmed.value = true;
      }
    };

    const close = () => {
      emit('close');
    };

    watch(open, (isOpen) => {
      if (!isOpen) {
        confirmed.value = false;
      }
    });

    return {
      challengeInput, isValid, confirm, close, confirmed,
    };
  },
});
</script>
